// import axios from 'axios'
import Service from './Service'
// import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { API_URL } from '../config'

const resource = `${API_URL}/trips`
// const resourceType = `${API_URL}/package-types`
const token = localStorage.getItem('token')
// const token = JSON.parse(sessionStorage.getItem('data'))

export default {
    getTrips (itemsPerPage, page, pageNumber, keyWord) {
      const cityFromQuery = (keyWord.cityFrom && keyWord.cityFrom !== '') ? '&cityFrom=' + keyWord.cityFrom : ''
      const cityToQuery = (keyWord.cityTo && keyWord.cityTo !== '') ? '&cityTo=' + keyWord.cityTo : ''
      const statusQuery = (keyWord.status && keyWord.status !== '') ? '&status=' + keyWord.status : ''
      const filterQuery = cityFromQuery + cityToQuery + statusQuery
        return Service.get(`${resource}/search?page=${page}&limit=${itemsPerPage}&pageNumber=${pageNumber}&${filterQuery}&withDriver=true&withShipments=true`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
}
