import Service from './Service'
import { API_URL } from '../config'
// import { RequestQueryBuilder } from '@nestjsx/crud-request'

const resource = `${API_URL}/cities`

const token = localStorage.getItem('token')
export default {
  getAllData () {
        return Service.get(`${resource}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
          console.log('response', response)
            if (response.status === 200) {
                return response.data
            }
        })
    },
}
