// import axios from 'axios'
import Service from './Service'
import { RequestQueryBuilder } from '@nestjsx/crud-request'
import { API_URL } from '../config'

const resource = `${API_URL}/shipments`
const resourceType = `${API_URL}/package-types`
const token = localStorage.getItem('token')
// const token = JSON.parse(sessionStorage.getItem('data'))

export default {
    getNewShipments (itemsPerPage, page, pageNumber, keyWord) {
        const condition = 1
        const queryString = `status=0&condition=${condition}&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`
        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getWithDriverShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=1&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getShippedShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=2&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getInWayShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=3&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getDeliveredShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=4&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getCanceledShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=5&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getDriverSnippingShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=6&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getSnippedShipments (itemsPerPage, page, pageNumber, keyWord) {
        const queryString = `status=7&senderName=${keyWord.senderName}&senderMobile=${keyWord.senderMobile}&driverName=${keyWord.driverName}&driverMobile=${keyWord.driverMobile}&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getRejectedShipments (itemsPerPage, page, pageNumber) {
const queryString = `condition=2&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    getResonRejectedShipments (shipmentId) {
        return Service.get(`${resource}/${shipmentId}/audits`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data.data
            }
        })
    },
    getPendingShipments (itemsPerPage, page, pageNumber) {
        const queryString = `status=0&condition=0&page=${page}&limit=${itemsPerPage}`

        return Service.get(`${resource}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    approveShipment (shipmentId) {
        return Service.post(`${resource}/${shipmentId}/validate`, {
            valid: true,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return true
            }
        })
    },
    rejectShipment (shipmentId, rejectionResson) {
        return Service.post(`${resource}/${shipmentId}/validate`, {
            valid: false,
            review: rejectionResson,
        }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return true
            }
        })
    },
    getAllShipmentType (itemsPerPage, page, pageNumber) {
        const queryString = RequestQueryBuilder.create({
            search: {},
            page: page,
            limit: itemsPerPage,
            pageNumber: pageNumber,
            sort: [{ field: 'id', order: 'DESC' }],
            resetCache: true,
        }).query()

        return Service.get(`${resourceType}?${queryString}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    updatePackage (packageId, Package) {
        const formData = new FormData()
        formData.append('name', Package.name)
        formData.append('details', Package.details)
        if (Package.icon === Object) {
            Package.icon = null
        } else {
            formData.append('icon', Package.icon)
        }
        return Service.patch(`${resourceType}/${packageId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
    addPackage (packageData) {
        const formData = new FormData()
        formData.append('icon', packageData.icon,
            packageData.icon.name)
        formData.append('name', packageData.name)
        formData.append('details', packageData.details)

        return Service.post(`${resourceType}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data.data
            }
        })
    },
    deletePackage (packageId) {
        return Service.delete(`${resourceType}/${packageId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (response.data.done) {
                return response.data
            }
        })
    },
}
